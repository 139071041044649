// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function arraysEqual(a: any[], b: any[]) {
    if (a === b) return true;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}
