import React, { FC, SVGProps } from 'react';

export const SolanaPayLogo: FC<SVGProps<SVGSVGElement>> = ({ width = 60, height = 22 }) => {
    return (
        <svg
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 60 22"
        >
            <linearGradient id="a" gradientUnits="userSpaceOnUse" x1="1.34093" x2="14.0922" y1="18.4332" y2="3.77438">
                <stop offset=".08" stopColor="#9945ff" />
                <stop offset=".3" stopColor="#8752f3" />
                <stop offset=".5" stopColor="#5497d5" />
                <stop offset=".6" stopColor="#43b4ca" />
                <stop offset=".72" stopColor="#28e0b9" />
                <stop offset=".97" stopColor="#19fb9b" />
            </linearGradient>
            <g fill="currentColor">
                <path d="m53.7996 15.3534-3.4639-7.56849h-2.875l4.9484 10.21209-.0891.3075c-.1239.4087-.3855.7617-.7402.9987-.3546.237-.7804.3433-1.2045.3007-.4965-.0046-.9836-.136-1.4152-.3819l-.4602 2.1873c.6641.2743 1.3748.4174 2.0931.4215 1.9794 0 3.1769-.7291 4.1666-2.8766l5.2404-11.16929h-2.7761z" />
                <path d="m29.3187 4.1792h-8.3034v13.912h2.7216v-5.1135h5.5818c3.0334 0 5.0276-1.5325 5.0276-4.39923 0-2.86672-1.9942-4.39927-5.0276-4.39927zm-.1484 6.3633h-5.4433v-3.96776h5.4433c1.5735 0 2.4742.71916 2.4742 1.98389s-.9007 1.98387-2.4742 1.98387z" />
                <path d="m45.7249 15.4526v-4.1909c0-2.47988-1.7764-3.76445-4.8247-3.76445-2.4742 0-4.6416 1.15562-5.2502 2.93115l2.2367.7936c.3315-.8878 1.4845-1.54247 2.9195-1.54247 1.7023 0 2.4198.69437 2.4198 1.54247v.2728l-4.0478.4464c-2.3307.2479-3.8647 1.2944-3.8647 3.1643 0 2.0483 1.7567 3.1494 4.0874 3.1494 1.5023.0469 2.9653-.4857 4.0874-1.4879.4057.9919.8214 1.6565 3.5876 1.3044v-2.0732c-1.1085.2678-1.351 0-1.351-.5456zm-2.4742-1.3292c0 1.3491-1.8853 2.0633-3.4639 2.0633-1.2024 0-1.9249-.3869-1.9249-1.1556 0-.7688.5938-1.0465 1.7419-1.1805l3.6568-.4265z" />
            </g>
            <path
                d="m15.8678 14.8277c.0193.0579.0193.1206 0 .1785-.0112.0578-.0386.1112-.0792.1538l-2.6177 2.7526c-.0577.0595-.1267.1067-.2029.1389-.0764.0336-.159.0505-.2424.0496h-12.415618c-.057498.0005-.114013-.0149-.163297-.0446-.0492514-.0336-.0885667-.0799-.1138136-.1339-.0174364-.0549-.0174364-.1138 0-.1687.0102614-.0571.0359328-.1102.0742256-.1537l2.622663-2.7527c.05768-.0594.12667-.1066.20288-.1388.07627-.034.15902-.051.24248-.0496h12.39578c.0598-.0012.1186.0161.1682.0496.0557.023.1014.0652.1287.119zm-2.6919-5.22755c-.059-.05787-.1277-.10488-.2029-.13887-.0772-.0312-.1593-.048-.2425-.0496h-12.420518c-.058217.00086-.115031.01804-.164007.04959-.0489763.03156-.088157.07623-.1131036.12896-.0171157.0549-.0171157.11373 0 .16863.0083338.0577.0342593.1114.0742256.15375l2.622663 2.75759c.05897.0579.12764.1049.20288.1389.07701.0316.15926.0484.24248.0496h12.39578c.0598.0011.1186-.0162.1682-.0496.05-.0292.0884-.0747.1089-.129.0254-.0527.0338-.1121.024-.1698-.0097-.0578-.0371-.1111-.0784-.1525zm-13.029215-2.03348c.049284.02969.105799.04513.163297.04464h12.420518c.0834.00086.1661-.01604.2425-.0496.0762-.03223.1452-.07945.2029-.13887l2.6177-2.75265c.0405-.04259.068-.09596.0792-.15375.0171-.0549.0171-.11373 0-.16863-.0205-.05422-.0589-.09974-.1089-.12895-.0497-.03342-.1084-.05074-.1683-.0496h-12.44028c-.08346-.00133-.1662.01559-.24247.0496-.07621.03223-.1452.07945-.20288.13887l-2.6177172 2.76256c-.0415084.04189-.0691515.09557-.0791755.15375-.01743638.05486-.01743638.11378 0 .16863.0321173.05302.0783988.09597.1336077.124z"
                fill="url(#a)"
            />
        </svg>
    );
};
